
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import React, { useState, useEffect, useRef } from 'react';
import Header from './Components/header';
import Footer from './Components/footer';
import {
  Container,
  Label,
  Input,
  FormGroup, Spinner
} from 'reactstrap';
import { FaPlus, FaCloudUploadAlt, FaRegCheckCircle, FaTshirt } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import sampleImage from './assets/images/banner.png';
import { ToastContainer, toast } from 'react-toastify';
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import FlipKart from './assets/icon/fk.png';
import Amaz from './assets/icon/amz.png';
import 'react-toastify/dist/ReactToastify.css';
import cloud from './assets/icon/cloud.svg';
import whatsapp from './assets/icon/whatsapp.svg';
import useDownloader from 'react-use-downloader';
import { usePostHog } from 'posthog-js/react';

const validImageTypes = ['image/jpeg', 'image/png', 'image/webp'];
const BASE_API_URL = 'https://ai-api.wiseant.co/';
const AWS_CONFIG = {
  REACT_APP_AWS_ACCESS_KEY_ID: 'AKIAU7GXY5FG6KKSKMVG',
  REACT_APP_AWS_SECRET_ACCESS_KEY: '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',
  REACT_APP_AWS_REGION: 'ap-south-1',
  REACT_APP_S3_BUCKET_NAME: 'wise-verification'
}

function App() {
  const userFileInputRef = useRef(null);
  const dress1InputRef = useRef(null);
  const dress2FileInputRef = useRef(null);
  const dress3FileInputRef = useRef(null);
  const [imageUserSrc, setUserImageSrc] = useState(null);
  const [imageUserFile, setUserImageFile] = useState(null);
  const [dress_1_Src, setDress1Src] = useState(null);
  const [dress1File, setDress1File] = useState(null);
  const [dress_2_Src, setDress2Src] = useState(null);
  const [dress2File, setDress2File] = useState(null);
  const [dress_3_Src, setDress3Src] = useState(null);
  const [dress3File, setDress3File] = useState(null);
  const [selectedDress, setSelectedDress] = useState(1);
  const [selectedCategoryValue, setSelectedValue] = useState('upper_body');
  const [successResponse, setSuccessResponse] = useState(null);
  const [loader, setLoader] = useState(false);
  const { download } = useDownloader();
  const posthog = usePostHog();

  const notify = (msg) => toast(msg, {
    autoClose: 1000
  });


  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    posthog.capture('Select Change', { value: 'event.target.value'})
  };

  useEffect(() => {
    const generateGuid = () => {
      if (!localStorage?.getItem('uuid')) {
        const newGuid = uuidv4();
        localStorage.setItem('uuid', newGuid)
      }
    };
    generateGuid();
  }, []);

  const handleFileChange = (event, inputType) => {
    const files = event.target.files; // Get selected files
    if (files.length > 0) {
      const file = event.target.files[0];
      const fileType = file.type;
      if (validImageTypes.includes(fileType)) {
        const reader = new FileReader(); // Create a FileReader instance
        reader.onload = (e) => {
          switch (inputType) {
            case 'user':
              setUserImageSrc(e.target.result);
              setUserImageFile(file);
              break;
            case 'dress_1':
              setDress1Src(e.target.result);
              setDress1File(file)
              break;
            case 'dress_2':
              setDress2Src(e.target.result);
              setDress2File(file)
              break;
            case 'dress_3':
              setDress3Src(e.target.result);
              setDress3File(file)
              break;

            default:
              break;
          }
        };
        reader.readAsDataURL(file);
      } else {
        notify('Please select a valid image file (JPEG, PNG, GIF, WEBP).');
      }
    }
  };

  const startProcessing = async () => {
    if (!imageUserFile) {
      notify('Please choose a full body photo.');
      return;
    }

    const uploadingFiles = [imageUserFile];

    switch (selectedDress) {
      case 1:
        if (!dress1File) {
          notify('Please Choose Your Dress 1.');
          return
        }
        uploadingFiles.push(dress1File)
        break;
      case 2:
        if (!dress2File) {
          notify('Please Choose Your Dress 2.');
          return
        }
        uploadingFiles.push(dress2File)
        break;
      case 3:
        if (!dress3File) {
          notify('Please Choose Your Dress 3.');
          return
        }
        uploadingFiles.push(dress3File)
        break;
      default:
        break;
    }
    setLoader(true);

    const s3r = await uploadToS3(uploadingFiles);
    if (s3r.length > 1) {
      const genImg = await createImage(s3r[0], s3r[1], selectedCategoryValue);

      if (genImg.success) {
        setSuccessResponse(genImg.result);
        posthog.capture('Image Generated', { value: 'Success'})
      } else {
        notify(genImg.message);
        posthog.capture('Image Generated', { value: 'Failed'})
      }
      setLoader(false);
    } else {
      setLoader(false);
      notify('Upload failed for some images.');
    }
  }



  const uploadToS3 = async (files) => {
    const s3 = new S3Client({
      region: AWS_CONFIG.REACT_APP_AWS_REGION,
      credentials: {
        accessKeyId: AWS_CONFIG.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: AWS_CONFIG.REACT_APP_AWS_SECRET_ACCESS_KEY
      }
    });

    const folderName = localStorage.getItem('uuid');

    try {
      const writeTos3 = await Promise.all(files.map(async (file) => {
        const params = {
          Bucket: AWS_CONFIG.REACT_APP_S3_BUCKET_NAME,
          Key: `${folderName}/${file.name}`,
          Body: file,
        };
        await s3.send(new PutObjectCommand(params));
        const fileUrl = `https://${AWS_CONFIG.REACT_APP_S3_BUCKET_NAME}.s3.${AWS_CONFIG.REACT_APP_AWS_REGION}.amazonaws.com/${folderName}/${file.name}`;
        return fileUrl; // Return the file URL
      }));
      return writeTos3;
    } catch (err) {
      console.error('Error uploading files:', err);
      return [];
    }
  }


  const createImage = async (user, dress, type) => {
    try {
      const response = await fetch(`${BASE_API_URL}api/vton`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_image_url: user,
          garment_image_url: dress,
          garment_category: type
        }),
      });

      if (!response.ok) {
        return {
          success: false,
          message: 'Failed to process image.'
        };
      }

      const result = await response.json();

      return {
        success: true,
        result: result
      };
    } catch (err) {
      return {
        success: false,
        message: 'Something went wrong, Failed to process image.'
      };
    }
  };

  const handleClick = (url) => {
    posthog.capture('Opened Link', { value: url ?? ""})
    window.open(url, "_blank");
  };

  const handleShare = async (url, text) => {
    posthog.capture('Shared Image', { value: url ?? "null"})
    if (!url) {
      return false;
    }

    if (navigator?.share) {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const file = new File([blob], 'share.jpg', {type: blob.type});
        
        await navigator.share({
          title: `${text} \n Created using WearWise`,
          text: text,
          url: 'https://wearwise.netlify.app/',
          files: [file]     
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      notify('Your browser does not support native sharing');
    }
  };

  return (
    <>
      <Header />
      <Container>

        <div className='container-section'>
          <div className='left-part fade-image-container'>
            <div className='relative'>
              <img src={successResponse?.vton_image ? successResponse.vton_image : sampleImage} alt="Sample" className="image" />
              {successResponse &&  <div className='icons-section'>
                <img src={cloud} alt='amazon' className='amz cursor' onClick={() => (download(successResponse?.vton_image, `wearwise_generated_${Date.now()}.png`), posthog.capture('Downloaded Generated Image'))} />
                <img src={whatsapp} alt='whatsapp' className='amz cursor' onClick={() => handleShare(successResponse?.vton_image, successResponse?.caption)} />
                {successResponse?.amazon_link && <img src={Amaz} alt='amazon' className='amz cursor' onClick={() => handleClick(successResponse?.amazon_link)} />}
                {successResponse?.flipkart_link && <img src={FlipKart} alt='flipkart' className='fk cursor' onClick={() => handleClick(successResponse?.flipkart_link)} />}
              </div>}
             
            </div>
          </div>
          <div className='right-part'>

            {/* <h4 className='mb-4 sub-title'>
              Transform your online shopping with our state-of-the-art virtual try-on feature.
              Our platform lets you see how your favorite clothes will look on you before you make a purchase.
            </h4> */}

            <div className='form-section'>
              <FormGroup>
                <Label>
                  Your Photo {imageUserSrc && <FaRegCheckCircle color='#6c62ff' />}
                </Label>
                <input
                  id="user_image"
                  name="file"
                  ref={userFileInputRef}
                  onChange={(e) => handleFileChange(e, 'user')}
                  type="file"
                  className="hidden"
                />
                <label className={'custom-file'}>
                  {imageUserSrc ? <img src={imageUserSrc} alt="user pic" className="image box-img" onClick={() => userFileInputRef?.current?.click()} /> : <FaPlus color="#c9c9c9" onClick={() => userFileInputRef?.current?.click()} />}
                </label>
              </FormGroup>
              <FormGroup className={imageUserSrc ? 'mr-20': ''}>
                <Label className='cursor' onClick={() => setSelectedDress(1)}>
                  Dress 1 &nbsp;
                  {selectedDress === 1 ? <FaRegCheckCircle color='#6c62ff' /> : null}
                </Label>
                <input
                  id="dress_1"
                  ref={dress1InputRef}
                  name="file"
                  onChange={(e) => handleFileChange(e, 'dress_1')}
                  type="file"
                  className="hidden"
                />
                <label className='custom-file'>
                  {dress_1_Src ? <img src={dress_1_Src} alt="dress 1" className="image box-img" onClick={() => dress1InputRef?.current?.click()} /> : <FaPlus color="#c9c9c9" onClick={() => dress1InputRef?.current?.click()} />}
                </label>
              </FormGroup>
              <FormGroup>
                <Label className='cursor' onClick={() => setSelectedDress(2)}>
                  Dress 2&nbsp;
                  {selectedDress === 2 ? <FaRegCheckCircle color='#6c62ff' /> : null}
                </Label>
                <input
                  id="dress_2"
                  name="file"
                  ref={dress2FileInputRef}
                  onChange={(e) => handleFileChange(e, 'dress_2')}
                  type="file"
                  className="hidden"
                />
                <label className='custom-file'>
                  {dress_2_Src ? <img src={dress_2_Src} alt="dress 2" className="image box-img" onClick={() => dress2FileInputRef?.current?.click()} /> : <FaPlus color="#c9c9c9" onClick={() => dress2FileInputRef?.current?.click()} />}
                </label>
              </FormGroup>
              {/* <FormGroup>
                <Label className='cursor' onClick={() => setSelectedDress(3)}>
                  Dress 3 &nbsp;
                  {selectedDress === 3 ? <FaRegCheckCircle color='#6c62ff' /> : null}
                </Label>
                <input
                  id="dress_3"
                  name="file"
                  ref={dress3FileInputRef}
                  onChange={(e) => handleFileChange(e, 'dress_3')}
                  type="file"
                  className="hidden"
                />
                <label className='custom-file'>
                  {dress_3_Src ? <img src={dress_3_Src} alt="dress 2" className="image" onClick={() => dress3FileInputRef?.current?.click()} /> : <FaPlus color="#c9c9c9" onClick={() => dress3FileInputRef?.current?.click()} />}
                </label>
              </FormGroup> */}
            </div>

            <FormGroup className='select-box'>
              <Label className='custom-font'>
                Category
              </Label>

              <Input
                className="mb-3 custom-select"
                type="select"
                value={selectedCategoryValue} onChange={handleSelectChange}
              >
                <option value={'upper_body'}>
                  Upper Body
                </option>
                <option value={'lower_body'}>
                  Lower Body
                </option>
                <option value={'dresses'}>
                  Full Body
                </option>

              </Input>

            </FormGroup>

            <div>
              <button className='btn-grad icon' onClick={startProcessing} disabled={loader} >

                {loader ? <Spinner size={'sm'}>
                  Loading...
                </Spinner> : <FaTshirt />}

                Try Now
              </button>


            </div>

            <h4 className='mt-4 mb-4 sub-title'>
              Transform your online shopping with our state-of-the-art virtual try-on feature.
              Our platform lets you see how your favorite clothes will look on you before you make a purchase.
            </h4>

          </div>
        </div>

        <ToastContainer />
        
      </Container>
      <Footer />
    </>
  );
}

export default App;
