import React from 'react';
import {
    Navbar,
    NavbarBrand
} from 'reactstrap';
import './footer.css';


const Footer = () => {
    return (
        <Navbar className='footer'>
            <NavbarBrand href="/">Made with ❤️ in Chennai</NavbarBrand>
        </Navbar>
    );
};

export default Footer;
