import React from 'react';
import {
    Navbar,
    NavbarBrand
} from 'reactstrap';
import './header.css';


const Header = () => {
    return (
        <Navbar>
            <NavbarBrand href="/">WearWise - Virtually Try On Any Dress</NavbarBrand>
        </Navbar>
    );
};

export default Header;
